import React from 'react';
import Styled from 'styled-components'
const imgMain = 'https://cdn-webth.garenanow.com/webth/cdn/garena/landing/2020motherday/banner.png';
const imgHr = 'https://cdn-webth.garenanow.com/webth/cdn/garena/landing/2020motherday/line.png';
const imgbg = 'https://cdn-webth.garenanow.com/webth/cdn/garena/landing/2020motherday/bg.jpg';

export default class extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            photo: imgMain,
            line: imgHr,
            org: "Garena",
            coltd: {
                Sea: "Sea (ประเทศไทย)",
                Garena: "บริษัท การีนา ออนไลน์ (ประเทศไทย) จำกัด",
                Shopee: "บริษัท ช้อปปี้ (ประเทศไทย) จำกัด",
                Seamoney: "บริษัท แอร์เพย์ (ประเทศไทย) จำกัด"
            },
        }
    }

    componentDidMount(){
        document.title = 'ทรงพระเจริญ'; // set title html
    }

    render() {
        return (
            <Landing>
                <div className="banner">
                    <img src={this.state.photo} alt=""/>
                </div>
                <div className="detail">
                    <h1>ทรงพระเจริญ</h1>
                    <div className="detail__line"><img src={this.state.line} alt=""/></div>
                    <div className="detail__specialtext">ด้วยเกล้าด้วยกระหม่อม ขอเดชะ</div>
                    <div style={{ lineHeight: "1em" }}>ข้าพระพุทธเจ้า คณะผู้บริหารและพนักงาน <br />{this.state.coltd.Garena}</div>
                    <a className="detail__button" href={this.props.uri}>เข้าสู่เว็บไซต์</a>
                </div>
            </Landing>
        )
    }
}

const Landing = Styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100vw;
    height: 100vh;
    background: top center no-repeat url(${imgbg});
    background-size: cover;
    color: #fff;
    font-size: 4vmin;
    overflow: hidden;
    font-family: 'PSLKandaModernProBold','Tahoma';
    @media(max-width: 1440px){
        font-size: 3.5vmin;
    }
    @media (max-width: 1024px){
        font-size: 3vmin;
    }
    @media (max-width: 768px){
        flex-direction: column;
    }
    @media (max-width: 500px){
        font-size: 3.5vmin;
    }
    @media (max-width: 300px){
        font-size: 4vmin;
    }
    @media (max-device-width: 1080px) and (orientation: portrait){
        font-size: 5vmin;
        flex-direction: column;
    }
    @media (max-device-width: 500px) and (orientation: portrait){
        font-size: 6vmin;
    }
    @media (max-device-width: 330px) and (orientation: portrait){
        height: auto;
        padding: 1.8em 0;
        font-size: 6vmin;
    }
    @media (max-device-width: 900px) and (orientation: landscape){
        font-size: 5vmin;
    }
    @media (max-device-width: 768px) and (orientation: landscape){
        flex-direction: row;
    }

    .banner {
        display: block;
        width: 30%;
        @media(max-width: 1440px){
            width: 25%;
        }
        @media(max-width: 1024px){
            width: 50%;
        }
        @media(max-width: 768px){
            width: 30%;
        }
        @media(max-width: 500px){
            width: 50%;
        }
        @media (max-device-width: 1080px) and (orientation: portrait){
            width: 50%;
        }
        @media (max-device-width: 500px) and (orientation: portrait){
            width: 70%;
        }
        @media (max-device-width: 330px) and (orientation: portrait){
            width: 55%;
        }
        @media (max-device-width: 1024px) and (orientation: landscape){
            width: 30%;
        }
        @media (max-device-width: 900px) and (orientation: landscape){
            width: 30%;
        }
    }

    .detail {
        display: block;
        width: 35%;
        text-align: center;
        padding: 1em;
        box-sizing: content-box;
        letter-spacing: 1px;
        white-space: nowrap;
        @media(max-width: 1080px){
            width: 100%;
            padding: 1em 0;
            position: relative;
            left: 0;
            white-space: normal;
        }
        @media (max-device-width: 1024px) and (orientation: landscape){
            width: 60%;
        }
        @media (max-device-width: 900px) and (orientation: landscape){
            width: 40%;
        }
        @media (max-device-width: 500px) and (orientation: landscape){
            width: 60%;
        }
        @media (max-device-width: 330px) and (orientation: portrait){
            padding: 0;
        }

        h1 {
            padding: 0px;
            margin: 0px;
            font-size: 12vmin;
            letter-spacing: 0.6vmin;
        } 

        div {
            line-height: 1.2em;
            @media(max-width: 1025px){
                display: block;
            }
        }

        .info {
            display: block;
            &__pc {
                display: block;
                @media(max-width: 980px){
                    display: none;
                }
                @media(max-device-width: 1024px) and (orientation: landscape){
                    display: none;
                }
                @media(max-width: 500px) and (orientation: portrait){
                    display: none;
                }
            }
            &__mobile {
                display: none;
                @media(max-width: 980px){
                    display: block;
                }
                @media(max-device-width: 1024px) and (orientation: landscape){
                    display: block;
                }
                @media(max-width: 500px) and (orientation: portrait){
                    display: block;
                }
            }
        }

        &__line {
            width: 30%;
            margin: 0 auto 2%;
        }

        &__specialtext {
            font-size: 4.3vmin;
            display: block;
            @media (max-device-width: 1080px) and (orientation: portrait){
                font-size: 6vmin;
            }
            @media (max-device-width: 500px) and (orientation: portrait){
                font-size: 7vmin;
            }
            @media (max-device-width: 900px) and (orientation: landscape){
                font-size: 6vmin;
            }
        }

        &__button {
            position: relative;
            display: block;
            width: fit-content;
            margin: 1.3em auto 0;
            padding: 0.3em 3em;
            border-radius: 40px;
            border: 1px solid #fff;
            color: #fff;
            outline: none;
            transition: all .5s;
            :hover, :active {
                background: #fff;
                color: #89b9da;
                transition: all .5s;
            }
        }
    }
`