import React, { useEffect, useState } from "react";
import queryString from "query-string";
import Landing from './../../features/landing';
import DOMPurify from "dompurify";

export default (props)=>{
    const [data,setData] = useState([]);
    function isValidHttpUrl(string) {
        try {
            const newUrl = new URL(string);
            return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
        } catch (err) {
            return false;
        }
    }

    useEffect(()=>{
        async function fetchData(){
            let data = {};
            let url = "https://cdn-webth.garenanow.com/webth/cdn/garena/lws20230915.json";
            let option = { 
                headers: { 'Accept': 'application/json'} 
            };
            let res = await fetch(url,option);
    
            if(res.status === 200) data = await res.json();
    
            setData(data);
        }
        fetchData(); 
    },[])

    let uri = (props && props.location && props.location.search) ? queryString.parse(props.location.search ).uri : "https://garena.co.th";
    let org = "garena";
    org = (uri.indexOf("seathailand.com") >= 0 || uri.indexOf("career.seathailand.com") >= 0 )? "Sea": "Garena";
    let clean_uri = isValidHttpUrl(uri) ?  data && data.indexOf(DOMPurify.sanitize(uri)) > -1 ? DOMPurify.sanitize(uri) : "https://garena.co.th" : "https://garena.co.th";

    return (
        <Landing
            org={org}
            uri={clean_uri}
        />
    )
}
