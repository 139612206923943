import React from 'react';
// import { combineReducers, createStore, applyMiddleware } from 'redux';
// import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

// Feature Components(Loadable)
// import { HomeLoadable } from './Loadable';
// Config Store with Localstorage
import configStore from './ConfigStore';
// Route Middleware
import Main from './pages/main';
import './styles/index.scss';
const { store, persistor } = configStore();

const Routes = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <div>
          <Route path="/"exact component={Main} />
          <Route path="/:org" component={Main} />
        </div>
      </Router>
    </PersistGate>
  </Provider>
);

export default Routes;
