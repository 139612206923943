import React from 'react';
// import Coronation2020 from '../../common/coronation_day_2020';
// import Queenbirthday2020 from '../../common/queen_birthday_2020';
// import Kingbirthday2020 from '../../common/2020_king_birthday';
import Motherday2020 from '../../common/2020_mother_day';

export default (props)=>{
    return (
         <Motherday2020
             org={props.org}
             uri={props.uri}
        />
    )
}
